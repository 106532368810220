<template>
    <div class="pay-box">
        <div class="pay-content">
            <div class="cart-title">
                <div class="title">
                    支付订单
                </div>
                <div class="warp-box">
                    <ul>
                        <li>
                            <div class="img">
                                <img src="../assets/img/cart-showIcon.png" alt="">
                            </div>
                            <div class="text">购物车</div>
                        </li>
                        <li>
                            <div class="img2">
                                <img src="../assets/img/cart-confirmShowIcon.png" alt="">
                            </div>
                            <div class="text">确认订单</div>
                        </li>
                        <li>
                            <div class="img3">
                                <img src="../assets/img/cart-paymenShowtIcon.png" alt="">
                            </div>
                            <div class="text">支付订单</div>
                        </li>
                        <li>
                            <div class="img4">
                                <img src="../assets/img/cart-successShowIcon.png" alt="">
                            </div>
                            <div class="text">完成</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="warp-content">
                <div class="container">
                    <div class="success-wrap">
                        <div class="img-box">
                            <img src="../assets/img/successIcon.png" alt="">
                        </div>
                        <div class="text-box">
                            <p class="pay-text">支付成功</p>
                            <p class="success-text">恭喜支付成功，我们会在12小时内为您开通课程！</p>
                        </div>
                        <div class="button-box">
                            <div class="buttonSlide-box">
                                <p @click="backIndex()">返回首页</p>
                            </div>
                            <div class="check-box">
                                <p @click="checkOrder()">查看订单</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return{
            orderId: this.$route.query.orderId
        }
    },
    metaInfo: {
        title: '订单购买成功-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        // 查看订单详情
        checkOrder() {
            this.$router.push({
                path: '/orderDetail',
                query: {
                    orderId: this.orderId
                }
            });
        },
        // 返回首页
        backIndex() {
            this.$router.push({
                path: '/index'
            });
        }
    }
}
</script>

<style lang="scss">
    .pay-box {
        padding-top: 92px;
        padding-bottom: 30px;
        width: 100%;
        min-width: 1200px;
        background: #eeeeee;
        .pay-content {
            margin-bottom: 30px;
            margin: 0 auto;
            width: 1200px;
            height: 833px;
            background: #FFFFFF;
            .cart-title {
                padding-left: 30px;
                padding-right: 30px;
                margin-bottom: 80px;
                display: flex;
                justify-content: space-between;
                height: 118px;
                border-bottom: 1px solid #EEEEEE;
                .title {
                    line-height: 118px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    color: #444444;
                }
                .warp-box {
                    padding-top: 30px;
                    ul {
                        display: flex;
                        li {
                            text-align: center;
                            .img {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img2 {
                                margin-bottom: 10px;
                                width: 94px;
                                height: 36px;
                            }
                            .img3 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                            .img4 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                            }
                        }
                    }
                }
            }
        }
        .warp-content {
            .container {
                .success-wrap {
                    margin: 0 auto;
                    width: 338px;
                    .img-box {
                        margin: 0 auto;
                        margin-bottom: 35px;
                        width: 90px;
                        height: 90px;
                    }
                    .text-box {
                        margin-bottom: 48px;
                        text-align: center;
                        .pay-text {
                            margin-bottom: 20px;
                            font-size: 26px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #444444;
                        }
                        .success-text {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #7F7E7E;
                        }
                    }
                    .button-box {
                        display: flex;
                        justify-content: space-between;
                        .buttonSlide-box {
                            width: 157px;
                            height: 40px;
                            line-height: 40px;
                            border: 1px solid #FF5A74;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF5A74;
                            text-align: center;
                            cursor: pointer;
                        }
                        .check-box {
                            width: 157px;
                            height: 40px;
                            line-height: 40px;
                            background: #FF5A74;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>